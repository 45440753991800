<template>
  <div>
    <vx-card v-if="empresa">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Empresa" icon-pack="feather" icon="icon-settings">
            <div class="tab-text">
              <empresa-edit-tab-account class="mt-4" :data="empresa" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import EmpresaEditTabAccount from './EditarEmpresaTab.vue';
export default {
  components: {
    EmpresaEditTabAccount,
  },
  data() {
    return {
      empresa: null,
      empresa_not_found: false,

      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    activeTab() {
      // this.fetch_user_data(this.$route.params.userId);
    },
  },
  created() {
    // this.$http.get('/api/empresa').then(({ data }) => {
    //   this.empresa = data;
    // });

    this.empresa = {
      ...this.activeUserInfo.company,
      imagen: 'https://ui-avatars.com/api/?size=128',
    };
  },
};
</script>

<style></style>
