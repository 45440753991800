var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editar-empresa-tab"},[_c('ValidationObserver',{ref:"observerClave",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('vs-prompt',{staticClass:"editar-empresa-tab__prompt",attrs:{"active":_vm.activePromptClave,"title":"Cambiar clave empresa","accept-text":"Cambiar","is-valid":!invalid,"cancel-text":"Descartar"},on:{"update:active":function($event){_vm.activePromptClave=$event},"accept":_vm.cambiarClave}},[_c('ValidationProvider',{attrs:{"name":"clave","vid":"clave_anterior","rules":"required|min:6|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":"Clave","type":"password"},model:{value:(_vm.clave_anterior),callback:function ($$v) {_vm.clave_anterior=$$v},expression:"clave_anterior"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"nueva clave","vid":"nueva_clave","rules":"required|min:6|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":"Nueva clave","type":"password"},model:{value:(_vm.clave_nueva),callback:function ($$v) {_vm.clave_nueva=$$v},expression:"clave_nueva"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}])}),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"empresa-edit-tab-account",attrs:{"id":"user-edit-tab-info"}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"flex items-start flex-col sm:flex-row"},[_c('img',{staticClass:"mr-8 rounded h-24 w-24",attrs:{"src":_vm.data.imagen}}),_c('div',[_c('p',{staticClass:"text-lg font-medium mb-2 mt-4 sm:mt-0"},[_vm._v(" "+_vm._s(_vm.data.nombre)+" ")]),_c('input',{ref:"update_avatar_input",staticClass:"hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.update_avatar}}),_c('vs-button',{staticClass:"mr-4 mb-4"},[_vm._v("Cambiar avatar")]),_c('vs-button',{attrs:{"type":"border"},on:{"click":function($event){_vm.activePromptClave = true}}},[_vm._v("Clave especial nueva")])],1)])])]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"vid":"codigo","name":"código","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":"Código"},model:{value:(_vm.data_local.code),callback:function ($$v) {_vm.$set(_vm.data_local, "code", $$v)},expression:"data_local.code"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"NIT","name":"NIT","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":"NIT"},model:{value:(_vm.data_local.nit),callback:function ($$v) {_vm.$set(_vm.data_local, "nit", $$v)},expression:"data_local.nit"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"flex flex-row flex-wrap w-full"},[_c('ValidationProvider',{staticClass:"w-full sm:w-1/2",attrs:{"vid":"telefonoPrincipal","name":"teléfono principal","rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full sm:pr-4 mt-4",attrs:{"label":"teléfono principal","placeholder":"+57 XXXXXXXXXX"},model:{value:(_vm.data_local.phone_first),callback:function ($$v) {_vm.$set(_vm.data_local, "phone_first", $$v)},expression:"data_local.phone_first"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"w-full sm:w-1/2",attrs:{"vid":"telefonoSecundario","name":"teléfono secundario","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"placeholder":"+57 XXXXXXXXXX","label":"Teléfono secundario"},model:{value:(_vm.data_local.phone_secondary),callback:function ($$v) {_vm.$set(_vm.data_local, "phone_secondary", $$v)},expression:"data_local.phone_secondary"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"vid":"nombre","name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":"Nombre"},model:{value:(_vm.data_local.name),callback:function ($$v) {_vm.$set(_vm.data_local, "name", $$v)},expression:"data_local.name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"direccion","name":"dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":"Dirección"},model:{value:(_vm.data_local.address),callback:function ($$v) {_vm.$set(_vm.data_local, "address", $$v)},expression:"data_local.address"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"email","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":"Email","type":"email"},model:{value:(_vm.data_local.email),callback:function ($$v) {_vm.$set(_vm.data_local, "email", $$v)},expression:"data_local.email"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"representanteLegal","name":"representante lega","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":"Representante legal"},model:{value:(_vm.data_local.user_legal),callback:function ($$v) {_vm.$set(_vm.data_local, "user_legal", $$v)},expression:"data_local.user_legal"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"mt-8 flex flex-wrap items-center justify-end"},[_c('vs-button',{staticClass:"ml-auto mt-2",attrs:{"disabled":invalid},on:{"click":_vm.save_changes}},[_vm._v("Guardar")]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Descartar")]),_c('vs-button',{staticClass:"ml-4 mt-2",attrs:{"type":"border","color":"warning"},on:{"click":_vm.reset_data}},[_vm._v("Reset")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }