<template>
  <div class="editar-empresa-tab">
    <ValidationObserver ref="observerClave" v-slot="{ invalid }">
      <vs-prompt
        class="editar-empresa-tab__prompt"
        :active.sync="activePromptClave"
        title="Cambiar clave empresa"
        @accept="cambiarClave"
        accept-text="Cambiar"
        :is-valid="!invalid"
        cancel-text="Descartar"
      >
        <ValidationProvider
          name="clave"
          vid="clave_anterior"
          v-slot="{ errors }"
          rules="required|min:6|max:10"
        >
          <vs-input
            v-model="clave_anterior"
            label="Clave"
            class="w-full mt-3"
            type="password"
          />
          <span class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="nueva clave"
          vid="nueva_clave"
          v-slot="{ errors }"
          rules="required|min:6|max:10"
        >
          <vs-input
            v-model="clave_nueva"
            label="Nueva clave"
            class="w-full mt-3"
            type="password"
          />
          <span class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>
      </vs-prompt>
    </ValidationObserver>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <div class="empresa-edit-tab-account" id="user-edit-tab-info">
        <!-- Avatar Row -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-start flex-col sm:flex-row">
              <img :src="data.imagen" class="mr-8 rounded h-24 w-24" />
              <!-- <vs-avatar :src="data.avatar" size="80px" class="mr-4" /> -->
              <div>
                <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">
                  {{ data.nombre }}
                </p>
                <input
                  type="file"
                  class="hidden"
                  ref="update_avatar_input"
                  @change="update_avatar"
                  accept="image/*"
                />

                <!-- Toggle comment of below buttons as one for actual flow & currently shown is only for demo -->
                <vs-button class="mr-4 mb-4">Cambiar avatar</vs-button>
                <!-- <vs-button type="border" class="mr-4" @click="$refs.update_avatar_input.click()">Change Avatar</vs-button> -->

                <vs-button @click="activePromptClave = true" type="border"
                  >Clave especial nueva</vs-button
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Content Row -->
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <ValidationProvider
              vid="codigo"
              name="código"
              rules="required|alpha_num"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-4"
                label="Código"
                v-model="data_local.code"
              />
              <span class="text-danger text-sm" v-show="errors">{{
                errors[0]
              }}</span>
            </ValidationProvider>

            <ValidationProvider
              vid="NIT"
              name="NIT"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-4"
                label="NIT"
                v-model="data_local.nit"
              />
              <span class="text-danger text-sm" v-show="errors">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <div class="flex flex-row flex-wrap w-full">
              <ValidationProvider
                vid="telefonoPrincipal"
                name="teléfono principal"
                class="w-full sm:w-1/2"
                rules="required|phone"
                v-slot="{ errors }"
              >
                <vs-input
                  class="w-full sm:pr-4 mt-4"
                  label="teléfono principal"
                  placeholder="+57 XXXXXXXXXX"
                  v-model="data_local.phone_first"
                />
                <span class="text-danger text-sm" v-show="errors">{{
                  errors[0]
                }}</span>
              </ValidationProvider>

              <ValidationProvider
                vid="telefonoSecundario"
                name="teléfono secundario"
                rules="phone"
                class="w-full sm:w-1/2"
                v-slot="{ errors }"
              >
                <vs-input
                  placeholder="+57 XXXXXXXXXX"
                  class="w-full mt-4"
                  label="Teléfono secundario"
                  v-model="data_local.phone_secondary"
                />
                <span class="text-danger text-sm" v-show="errors">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <ValidationProvider
              vid="nombre"
              name="nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-4"
                label="Nombre"
                v-model="data_local.name"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              vid="direccion"
              name="dirección"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-4"
                label="Dirección"
                v-model="data_local.address"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              vid="email"
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-4"
                label="Email"
                type="email"
                v-model="data_local.email"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>

            <ValidationProvider
              vid="representanteLegal"
              name="representante lega"
              rules="required|alpha_spaces"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full mt-4"
                label="Representante legal"
                v-model="data_local.user_legal"
              />
              <span class="text-danger text-sm">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-8 flex flex-wrap items-center justify-end">
              <vs-button
                :disabled="invalid"
                class="ml-auto mt-2"
                @click="save_changes"
                >Guardar</vs-button
              >

              <vs-button type="border" class="ml-4 mt-2" @click="$router.go(-1)"
                >Descartar</vs-button
              >
              <vs-button
                type="border"
                class="ml-4 mt-2"
                color="warning"
                @click="reset_data"
                >Reset</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import citiesMixin from '@/mixins/cities-mixin';
import { UPDATE_COMPANY, UPDATE_SPECIAL_KEY } from '@/graphql/mutations';
import _ from 'lodash';
export default {
  mixins: [citiesMixin],
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),

      //VSPromptClave
      activePromptClave: false,
      clave_anterior: '',
      clave_nueva: '',

      companyLocal: null,
    };
  },
  computed: {},
  mounted() {
    if (this.data_local.city) {
      this.departmentSelected = this.data_local.city.department.id;
      this.$nextTick(() => {
        this.citySelected = this.data_local.city.id;
      });
    }
  },
  methods: {
    async cambiarClave() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_SPECIAL_KEY,
          variables: {
            current_special_key: this.clave_anterior,
            new_special_key: this.clave_nueva,
          },
          error(error) {
            console.log(error.message);
          },
        });

        this.$vs.notify({
          color: 'success',
          title: 'Cambio de clave exitoso!',
          text: 'La clave se cambio satisfactoriamente.',
        });
      } catch (error) {
        this.$vs.notify({
          title: 'Clave incorrecta!',
          text: 'La clave no coincide con la contraseña anterior.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
    },
    save_changes() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
          return;
        }

        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_COMPANY,
          variables: { ...this.data_local, city_id: this.citySelected },
        });

        const company = _.omit(data.updateCompany, [
          '__typename',
          'city.__typename',
          'city.department.__typename',
        ]);

        this.$vs.notify({
          color: 'success',
          title: 'Actualización exitosa',
          text: 'Se actualizo la información correctamente!',
        });

        this.$store.commit('UPDATE_USER_INFO', { company: company });

        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      });
      // if (!this.validateForm) return;
      // Here will go your API call for updating data
      // You can get data in "this.data_local"
    },
    reset_data() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';
.editar-empresa-tab {
  span {
    @apply leading-tight;
  }
  &__prompt {
    .dialog-title {
      color: $nepal !important;
    }
  }
}
</style>
